import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    loading: false,
    menuOpen: false,
    localeChange: 'pt',
    activeMenuFooter: false,
    activeFormFloating: false,
    isDesktop: null,
    locale:  null,
    visitorId: null,
    urls: {},
  },
  actions,
  getters,
  mutations,
};
