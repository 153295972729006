import { axiosInstance } from '@/plugins/axios-instance';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { mapGetters } from 'vuex';
import PasswordLogin from './PasswordLogin';

export default {
    name: "ModalLogin",
    data() {
      return {
        mobile: '',
        mobileCountryCode: '',
        mobileLogged: false,
      }
    },
    components: {
        VuePhoneNumberInput,
        PasswordLogin
    },
    computed: {
      ...mapGetters('Site', ['urls'])
    },
    methods: {
      changePhone(payload){
        if('countryCallingCode' in payload){
          this.mobileCountryCode = payload.countryCallingCode;
        }
      },
      handleSubmit($event){
        const mobileWithoutSpaces = this.mobile.replace(/\s/g, '');
        /* console.log("celular",mobileWithoutSpaces); */
        axiosInstance({
          method: 'POST',
          url: 'customer/login-mobile',
          data: {
            mobile_country_code: this.mobileCountryCode,
            mobile: mobileWithoutSpaces,
          }
        }).then(response => response.data.data)
        .then(data => {
          this.$bvModal.hide("modal-login");
          this.mobileLogged = true;
          setTimeout(() => {
            this.$bvModal.show("modal-login-password");
          }, 500);
        })
      }
    },
};
