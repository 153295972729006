import { axiosInstance } from '@/plugins/axios-instance';
import { mapGetters } from 'vuex';

export default {
    name: "ModalPasswordLogin",
    data() {
      return {
        password: '',
      }
    }, 
    props: {
      mobile: String,
    },
    computed: {
      ...mapGetters('Site', ['urls'])
    },
    methods: {
      requestPassword(){
        const self = this;

        this.$swal.fire({
          html: 'Deseja receber a nova password em seu telemóvel?',
          confirmButtonText: "Sim",
          denyButtonText: "Não",
          showDenyButton: true,
        }).then(result => {
          if(result.isConfirmed){
            axiosInstance({
              method: 'POST',
              url: 'customer/request-password',
              data: {
                mobile: this.mobile
              }
            }).then(response => response.data.data)
            .then(data => {
                self.$swal.fire({
                  type: 'success',
                  html: data.message
                })
            });
          }
        })
      },
      handleSubmit($event){
        axiosInstance({
          method: 'POST',
          url: 'customer/login-password',
          data: {
            password: this.password,            
            mobile: this.mobile,
            login: this.mobile
          }
        }).then(response => response.data.data)
        .then(data => {
          this.$swal.fire({
            type: 'success',
            html: data.message,            
          }).then(result =>{
            if(result.isConfirmed){
              //redirect user
              document.location.href= `${this.urls.customerDashboard}?accessToken=${data.accessToken}` 
            }         
          });          
        })
      }
    },
};
