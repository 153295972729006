export default {  
  setLoading(state, value){
    state.loading = value;
  },  
  setVisitorId(state, value){
    state.visitorId = value;
  },  
  setUrls(state, value){
    state.urls = value
  },
  setMenuOpen(state, value) {
    state.menuOpen = value;
  },
  setLocale(state, value){
    state.locale = value;
  },
  setActiveMenuFooter(state, value) {
    state.activeMenuFooter = value;
  },
  setActiveFormFloating(state, value) {
    state.activeFormFloating = value;
  },
  setIsDesktop(state, value) {
    state.isDesktop = value;
  },
  setActiveBackdrop(state, value) {
    state.activeBackdrop = value;
    if (value) {
      document
        .getElementsByTagName("body")[0]
        .classList.add("overflow-hidden");
    } else {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("overflow-hidden");
    }
  },
};