import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:slug?',
    name: 'home',
    component: () => import(/* webpackChunName: "/" */ '@/views/Home'),
  },
  {
    path: '/event/:slug?',
    name: 'event-details',
    component: () => import(/* webpackChunName: "event" */ '@/views/EventDetails'),
  },
  {
    path: '/restrict/:slug?',
    name: 'restrict-details',
    component: () => import(/* webpackChunName: "event" */ '@/views/EventDetails'),
  },
  {
    path: '/payment/redirect',
    name: 'payments-redirect',
    component: () => import(/* webpackChunName: "event" */ '@/views/PaymentsRedirect'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
})

// Função para carregar o script
function loadScript() {
  const existingScript = document.getElementById('cookie-script');
  if (!existingScript) {
    const script = document.createElement('script');
    script.id = 'cookie-script';
    script.type = 'text/javascript';
    script.charset = 'UTF-8';
    script.src = '//cdn.cookie-script.com/s/3f713bce142a1e0d9a102b7fbe7b4cad.js';
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      console.log('Script carregado com sucesso.');
    };

    script.onerror = () => {
      console.error('Erro ao carregar o script.');
    };
  }
}

// Guard de rota
router.beforeEach((to, from, next) => {
  if (to.name === 'home' || to.name === 'event-details') {
    loadScript();
  } else {
    // Opcional: remover o script se não estiver em uma das rotas específicas
    const existingScript = document.getElementById('cookie-script');
    if (existingScript) {
      existingScript.remove();
    }
  }
  next();
});

export default router
