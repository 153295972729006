import Vue from 'vue'
import Vuex from 'vuex'
import Site from './Site';
import Cart from './Cart';
import Event from './Event';

import Checkout from '@monorepo/zicket-vue-checkout/src/store/Checkout';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Site,
    Cart,
    Event,
    Checkout
  }
})
