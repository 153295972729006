import { axiosInstance, axiosWithoutLoading } from "@/plugins/axios-instance";

export default {
  loading(context, value) {
    context.commit('setLoading', value)
  },
  setCart(context, value) {
    context.commit("setCart", value)
  },
  async addCartItem(context, params) {
    let cart = context.state.cart;
    if (!cart.items) {
      cart.items = [];
    }

    // Modifica a lógica para suportar tanto tickets com sessão quanto eventos sem sessão
    const itemIdentifier = params.session_id ? `${params.ticket_id}_${params.session_id}` : params.event_id ? `${params.ticket_id}_${params.event_id}` : `${params.ticket_id}`;
    const existingItemIndex = cart.items.findIndex(item =>
      params.session_id ? (item.ticket_id === params.ticket_id && item.session_id === params.session_id) :
        params.event_id ? (item.ticket_id === params.ticket_id && item.event_id === params.event_id) :
          item.ticket_id === params.ticket_id);

    if (existingItemIndex !== -1) {
      cart.items[existingItemIndex].amount += params.amount;
    } else {
      cart.items.push({
        ...params,
        identifier: itemIdentifier
      });
    }

    // Prepara os dados a serem enviados para a API. Se 'session_date' está presente, formata a data.
    const formattedParams = params.session_date ? {
      ...params,
      session_date: params.session_date.split('-').reverse().join('/')
    } : { ...params };

    context.commit('setCart', cart);

    // Faz a requisição à API
    return axiosInstance({
      method: 'POST',
      url: `cart/add-item`,
      data: formattedParams
    }).then(response => response.data.data)
      .then(data => {
        context.commit('setCart', data);
        return data;
      });
  },
  async getCart(context) {
    context.commit('setLoading', true)

    return axiosWithoutLoading({
      method: 'GET',
      url: `cart`
    }).then(response => response.data.data)
      .then(data => {
        context.commit('setLoading', false)
        context.commit('setCart', data);

        return data;
      })
  },
  resetCart(context) {
    context.commit('setCart', {});
  },

};
