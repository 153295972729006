import { axiosInstance } from "@/plugins/axios-instance";

export default {
  loading(context, value) {
    context.commit('setLoading', value)
  },
  setEvent(context, value) {
    context.commit("setEvent", value)
  },
  setOrganizer(context, value) {
    context.commit("setOrganizer", value)
  },
  setOrganizerSlug(context, value) {
    context.commit("setOrganizerSlug", value)
    localStorage.setItem("zicket-organizer", value);
  },
  setEventSlug(context, value) {
    context.commit("setEventSlug", value)
    localStorage.setItem("zicket-event", value);
  },
  async getEvent(context) {
    context.commit('setLoading', true)
    const eventSlug = context.getters.eventSlug;
    return axiosInstance({
      method: 'GET',
      url: `event/${eventSlug}`
    }).then(response => response.data.data)
      .then(data => {
        context.commit('setLoading', false)
        context.commit('setEvent', data);

        return data;
      })
  },
  async getOrganizer(context) {
    context.commit('setLoading', true)

    return axiosInstance({
      method: 'GET',
      url: `organizer/${context.getters.organizerSlug}`
    }).then(response => response.data.data)
      .then(data => {
        context.commit('setLoading', false)
        context.commit('setOrganizer', data);

        return data;
      })
  }
};
