export default {
  setLoading(state, value) {
    state.loading = value;
  },
  setOrganizer(state, value) {
    state.organizer = value;
  },
  setEvent(state, value) {
    state.event = value;
  },
  setOrganizerSlug(state, value) {
    state.organizerSlug = value;
  },
  setEventSlug(state, value) {
    state.eventSlug = value;
    localStorage.setItem('zicket-event', value);
  },
};
