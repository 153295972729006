import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    loading: false,
    organizerSlug: null,
    eventSlug: null,
    organizer: {},
    event: {},    
  },
  actions,
  getters,
  mutations,
};